// Based on: https://www.co2emissiefactoren.nl/lijst-emissiefactoren/#personenvervoer
// Flights < 700km : 0.297 kg CO2 / passenger / km
// Flights < 2500km: 0.200 kg CO2 / passenger / km
// Flights > 2500km: 0.147 kg CO2 / passenger / km

// Distances and CO2 emissions: based on https://www.prokerala.com/travel/airports/distance/ / Google Maps
// AMS - ORY (Paris Orly): 432.78km   -> 128.53566 kg CO2
// AMS - JFK (New York): 5849.84km    -> 859,92648 kg CO2
// AMS - DXB - DBS (Dubai -> Denpasar, Bali): 5169.63 + 7493.98 = 12663.61 km -> 1861.55067 kg CO2
// AMS - FCO (Rome): 1297.5km         -> 259.5 kg CO2

/**
 * Item with amount of CO2 emissions in kg.
 */
export const CO2EMISSIONEXAMPLES: Record<string, number> = {
  "Flight-AMS-ORY": 128.53566,
  "Flight-AMS-FCO": 259.5,
  "Flight-AMS-JFK": 859.92648,
  "Flight-AMS-DXB-DPS": 1861.55067,
  "Car-avg-diesel-1km": 0.213,
};

export class Co2ComparisonService {
  /**
   * The average number of days per year.
   * See https://pumas.jpl.nasa.gov/files/04_21_97_1.pdf.
   */
  private DAYSPERYEAR: number = 365.2422;

  private co2EmissionExamples: Record<string, number>;

  constructor() {
    this.co2EmissionExamples = CO2EMISSIONEXAMPLES;
  }

  /**
   * Compare a specified amount of emissions with some examples (for example a flight from AMS to JFK).
   * @param emissionsKg The amount of emissions that need to be compared.
   */
  getCo2Comparisons(emissionsKg: number): Record<string, number> {
    // Key/value containing the item to compare to and as value the number of times `emissionKg` fits in.
    let co2Comparison: Record<string, number> = {
      "Flight-AMS-ORY": 0,
      "Flight-AMS-FCO": 0,
      "Flight-AMS-JFK": 0,
      "Flight-AMS-DXB-DPS": 0,
      "Car-avg-diesel-1km": 0,
    };

    // Now convert the value to the number of times the specified emissions fit in the comparison.
    // Example: if a flight has an emission of 1000 kg CO2 and emissionsKg is 2000,
    //  then you could have two flights by comparison. So the value will be 2.
    Object.keys(co2Comparison).forEach((key) => {
      co2Comparison[key] = emissionsKg / this.co2EmissionExamples[key];
    });

    return co2Comparison;
  }
}
