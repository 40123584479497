import { AttributeService } from "./attribute.service";
import { Config } from "../config";

/**
 * This services animates numbers from start point to endpoint.
 */
export class AnimateService {
  private steps: number;
  private period: number;

  constructor(private attributeService: AttributeService, private config: Config) {
    this.steps = this.attributeService.getFirstNumberValue("data-animate-steps", config.animateSteps);
    this.period = this.attributeService.getFirstNumberValue("data-animate-period", config.animatePeriod);
  }

  animate() {
    let counters = document.querySelectorAll("[data-target]");

    counters.forEach((counter) => {
      const updateCount = () => {
        const targetValue = this.attributeService.getNumberValue(counter, "data-target", 0);
        const initialValue = this.attributeService.getNumberValue(counter, "data-initial", 0);
        const currentValue = parseInt(counter.innerHTML == "" ? "0" : counter.innerHTML.replace(".", ""));

        /* How much to increase the current value in this step. */
        const increase = (targetValue - initialValue) / this.steps;

        if (currentValue < targetValue) {
          counter.innerHTML = Math.ceil(currentValue + increase).toLocaleString("nl-NL");
          setTimeout(updateCount, this.period);
        } else {
          counter.innerHTML = Math.ceil(targetValue).toLocaleString("nl-NL");
        }
      };

      updateCount();
    });
  }
}
