import { ServerResponse } from 'http';
import { AxiosInstance, AxiosResponse } from 'axios';
import { GraphQLResponse } from '../model/GraphQLResponse';

const axios = require('axios');

export class Data {
  test: string | undefined;
}

export class UnFootprintService {
  private _profileQuery = `profiles(handle: $handle) {
    name
    accountId
    handle
    itemCount
    level
    logo
    logoSquare
  }`;

  private _statisticsQuery = `statistics(accountId: $accountId) {
    cO2Intake {
      grams
      intakeKgPerSecond
      kilograms
      kilogramsTotal
      tonnes
    }
    itemCount
  }`;

  constructor(private instance: AxiosInstance) {}

  getUnFootprintData(handle: string, accountId: string, datasets: string[]): Promise<AxiosResponse<GraphQLResponse>> {
    // Make a request for a user with a given ID.
    return this.instance.post<GraphQLResponse>('/', {
      operationName: 'data',
      variables: {
        handle: handle,
        accountId: accountId
      },
      query: this.getQuery(datasets)
    });
  }

  private getQuery(datasets: string[]): string {
    // let query = `query data($handle: String) {\n`;
    let query = `query data(`;

    // Now based on the dataset loaded, get the right parameters.
    if (datasets.indexOf('profile') > -1 && datasets.indexOf('statistics') > -1) {
      query += '$handle: String, $accountId: ID';
    } else if (datasets.indexOf('profile') > -1) {
      query += '$handle: String';
    } else {
      query += '$accountId: ID';
    }
    query += ') {\n';

    if (datasets.indexOf('profile') > -1) {
      query += this._profileQuery;
    }
    if (datasets.indexOf('statistics') > -1) {
      query += this._statisticsQuery;
    }

    query += '\n }';

    return query;
  }
}
