/**
 * This class reads attributes from the widgets.
 */
export class AttributeService {
  /**
   * Get the value of the specified attribute of the first element containing that attribute, or null.
   * @param attributeName The attribute name to search.
   */
  getFirstAttributeValue(attributeName: string): string | null {
    // Retrieves one element, or null if not found.
    let element = document.querySelector(`[${attributeName}]`);

    if (element == null) {
      // No widget on the current page. Return null.
      return null;
    }

    // At least one widget on the current page. Return the handle of the first widget.
    return element.getAttribute(attributeName);
  }

  /**
   * Get the first element on a page that has a certain attribute and return the value as a number.
   * @param attributeName The attribute name to look for.
   * @param defaultValue The value if no element is found.
   */
  getFirstNumberValue(key: string, defaultValue: number): number {
    return parseInt(this.getFirstAttributeValue(key) || defaultValue.toString());
  }

  /**
   * Get a number value from a specific element. Ignores dots and , as the number might be split using a locale (fe. 10.000.000)
   * @param element The element to search.
   * @param key The attribute name to search for.
   * @param defaultValue The default value if it isn't found.
   */
  getNumberValue(element: Element, key: string, defaultValue: number): number {
    return parseInt(element.getAttribute(key)?.replace(".", "").replace(",", "") || defaultValue.toString());
  }

  /**
   * Get the handle of the profile for which the widget is intended.
   */
  getHandle(): string | null {
    return this.getFirstAttributeValue("data-handle");
  }

  /**
   * Get whether numbers should be animated or not.
   */
  getAnimate(): boolean {
    return this.getFirstAttributeValue("data-animate") == "true";
  }

  /**
   * Get the environment on which data needs to be fetched.
   * Either development, test, acceptance or production (or null if not specified).
   */
  getEnvironment(): string | null {
    return this.getFirstAttributeValue("data-environment");
  }

  /**
   * Get the expiry time in additional minutes.
   */
  getExpiryTime(): number | null {
    let expiryTime = this.getFirstAttributeValue("data-expiry");

    if (expiryTime == null) {
      return null;
    }

    return parseInt(expiryTime);
  }

  /**
   * Get the accountId of the profile for which the widget is intended.
   */
  getAccountId(): string | null {
    return this.getFirstAttributeValue("data-accountid");
  }

  /**
   * Get the set of data that needs to be loaded for the given widgets.
   * IE. if a widget needs profile data then it contains the attribute 'data-get="profile"'.
   */
  getDataSets(): string[] {
    let widgets = document.getElementsByClassName("unfootprint-widget");

    let datasets: string[] = [];

    for (var i = 0; i < widgets.length; i++) {
      const get = widgets[i].getAttribute("data-get");

      // One data-get attribute might contain several datasets to be loaded. Example: 'profile statistics'.
      let splitted = get?.split(" ");

      if (splitted === undefined) {
        break;
      }

      for (var j = 0; j < splitted.length; j++) {
        // If the dataset isn't already in the list, add it.
        if (splitted[j] !== "" && datasets.indexOf(splitted[j]) === -1) {
          datasets.push(splitted[j]);
        }
      }
    }

    return datasets;
  }

  populateElements(key: string, value: string) {
    let elements = document.getElementsByClassName(key);

    for (var i = 0; i < elements.length; i++) {
      elements[i].innerHTML = value;
    }
  }

  populateNumericElements(key: string, value: string, animate: boolean) {
    let elements = document.getElementsByClassName(key);

    for (var i = 0; i < elements.length; i++) {
      if (animate) {
        elements[i].setAttribute("data-target", value);

        /* If the element isn't filled: start from 0.
         * If it's filled: keep the value. For example the widget may contain a value from cache.
         * Use that as a starting point then.
         */
        if (elements[i].innerHTML == "") {
          elements[i].setAttribute("data-initial", "0");
          elements[i].innerHTML = "0";
        } else {
          elements[i].setAttribute("data-initial", elements[i].innerHTML);
        }
      } else {
        elements[i].innerHTML = value;
      }
    }
  }
}
