import { Config } from "../config";
import { ProfileType, Co2StatisticType } from "../model/graphql/graphql";
import { AttributeService } from "./attribute.service";

/**
 * This class stores and reads data from local storage.
 */
export class StorageService {
  constructor(private config: Config, private attributeService: AttributeService) {}

  setDataExpiryDate() {
    // Calculate the expiration date.

    /* If an HTML elements attribute provides the expiry time, use that. Otherwise, use the default set in the config. */
    let expiryTimeAttribute = this.attributeService.getExpiryTime();
    let minutesToExpire = expiryTimeAttribute != null ? expiryTimeAttribute : this.config.dataExpiryInMinutes;

    var expireAt = new Date();
    expireAt.setMinutes(expireAt.getMinutes() + minutesToExpire);

    localStorage.setItem("widgetExpireAt", JSON.stringify(expireAt));
  }

  getDataExpiryDate(): Date | null {
    let expireAt = localStorage.getItem("widgetExpireAt");

    return expireAt !== null ? new Date(JSON.parse(expireAt)) : null;
  }

  isDataExpired(): boolean {
    let expireAt = this.getDataExpiryDate();

    if (expireAt == null) {
      return true;
    }

    return new Date() >= expireAt;
  }

  isLoggingEnabled(): boolean {
    let isLoggingEnabled = localStorage.getItem("widgetEnableLogging");

    return isLoggingEnabled !== null && isLoggingEnabled == "true";
  }

  /**
   * Get a list which of the datasets need to be inserted or refreshed.
   * If the dataset doesn't exist in storage, it needs to be added.
   * If the dataset exists in storage, but it's expired, it needs to be refreshed.
   * @param datasets The datasets to check.
   */
  getDatasetsToRefresh(datasets: string[]) {
    // Data is expired. All the given datasets need to be refreshed.
    if (this.isDataExpired()) {
      return datasets;
    }

    let datasetsToAdd: string[] = [];

    for (let i = 0; i < datasets.length; i++) {
      // Check if the data is already in local storage. If not, mark it to be added.
      if (localStorage.getItem("data-" + datasets[i]) === null) {
        datasetsToAdd.push(datasets[i]);
      }
    }

    return datasetsToAdd;
  }

  getProfileData(): ProfileType | null {
    let profile = localStorage.getItem("data-profile");

    if (profile == null) {
      return null;
    }

    let data: ProfileType = JSON.parse(profile);

    return data;
  }

  getStatisticsData(): Co2StatisticType | null {
    let profile = localStorage.getItem("data-statistics");

    if (profile == null) {
      return null;
    }

    let data: Co2StatisticType = JSON.parse(profile);

    return data;
  }

  setProfileData(data: ProfileType) {
    localStorage.setItem("data-profile", JSON.stringify(data));
  }

  setStatisticsData(data: Co2StatisticType) {
    localStorage.setItem("data-statistics", JSON.stringify(data));

    // Set the time at which the statistics are set, to calculate increase in intake in the meantime.
    localStorage.setItem("data-statistics-timestamp", JSON.stringify(new Date()));
  }

  /**
   * Get the date at which the statistics were saved to local storage.
   */
  getStatisticsDataExpiryDate(): Date | null {
    let expireAt = localStorage.getItem("data-statistics-timestamp");

    return expireAt !== null ? new Date(JSON.parse(expireAt)) : null;
  }
}
