import { UnFootprintService } from "./services/unfootprint.service";
import { Config } from "./config";
import { ProfileType, Co2StatisticType } from "./model/graphql/graphql";
import { AxiosResponse } from "axios";
import { AttributeService } from "./services/attribute.service";
import { GraphQLResponse } from "./model/GraphQLResponse";
import { StorageService } from "./services/storage.service";
import { Co2ComparisonService } from "./services/co2-comparison.service";
import { AnimateService } from "./services/animate.service";

const axios = require("axios");

export class Widgets {
  constructor(private unFootprint: UnFootprintService, private attributeService: AttributeService, private storageService: StorageService, private comparisonService: Co2ComparisonService, private animateService: AnimateService) {}

  getData(handle: string | null, accountId: string | null) {
    // First get the profile handle for which the widgets need to get the data.
    if (!handle) {
      handle = this.attributeService.getHandle();
    }
    if (!accountId) {
      accountId = this.attributeService.getAccountId();
    }

    if (!handle || !accountId) {
      info("No widget is found on the page. Don't get any data.");
      return;
    }

    info(this.storageService.isDataExpired() ? "Data is expired" : "Data is not yet expired");

    // Get the datasets to be loaded.
    let datasets = this.attributeService.getDataSets();

    // Populate the fields with the existing data stored in local storage.
    log("Populate datasets: ", datasets);
    this.populate(datasets);

    // Now determine which datasets to load from unFootprint API.
    // If the stored is expired, all datasets need to be reloaded.
    let datasetsToRefresh = this.storageService.getDatasetsToRefresh(datasets);

    if (datasetsToRefresh.length > 0) {
      log("Refreshing datasets: ", datasetsToRefresh);

      // Refresh the data from the unFootprint API.
      this.refreshData(handle, accountId, datasetsToRefresh);
    } else {
      log("No datasets to refresh");
    }
  }

  populate(datasets: string[]) {
    let animate = this.attributeService.getAnimate();

    if (datasets.indexOf("profile") > -1) {
      this.populateProfileData(this.storageService.getProfileData(), animate);
    }
    if (datasets.indexOf("statistics") > -1) {
      this.populateStatisticsData(this.storageService.getStatisticsData(), animate);
    }

    if (animate) {
      this.animateService.animate();
    }
  }

  populateProfileData(data: ProfileType | null, animate: boolean) {
    if (data == null) {
      return;
    }

    info("Populate Profile data", data);

    this.attributeService.populateElements("profile-name", data.name);
    this.attributeService.populateNumericElements("trees-planted", data.itemCount.toString(), animate);
  }

  populateStatisticsData(data: Co2StatisticType | null, animate: boolean) {
    if (data == null) {
      return;
    }
    info("Populate Statistics data", data);

    // Populate data directly from the server.
    this.attributeService.populateNumericElements("item-count", data.itemCount?.toString(), animate);
    this.attributeService.populateNumericElements("co2-grams", data.cO2Intake?.grams!.toString() || "", animate);
    this.attributeService.populateNumericElements("co2-kilograms", data.cO2Intake?.kilograms!.toString() || "", animate);
    this.attributeService.populateNumericElements("co2-tonnes", data.cO2Intake?.tonnes!.toString() || "", animate);
    this.attributeService.populateNumericElements("co2-kilograms-total", data.cO2Intake?.kilogramsTotal!.toString() || "", animate);
    this.attributeService.populateNumericElements("trees-planted", data.itemCount.toString(), animate);

    // Include CO2 comparison statistics.
    let comparisons = this.comparisonService.getCo2Comparisons(data.cO2Intake!.kilogramsTotal);

    this.attributeService.populateNumericElements("flight-ams-ory", comparisons["Flight-AMS-ORY"].toFixed(0), animate);
    this.attributeService.populateNumericElements("flight-ams-fco", comparisons["Flight-AMS-FCO"].toFixed(0), animate);
    this.attributeService.populateNumericElements("flight-ams-jfk", comparisons["Flight-AMS-JFK"].toFixed(0), animate);
    this.attributeService.populateNumericElements("flight-ams-dxb-dps", comparisons["Flight-AMS-DXB-DPS"].toFixed(0), animate);
    this.attributeService.populateNumericElements("car-avg-diesel-1km", comparisons["Car-avg-diesel-1km"].toLocaleString("nl-NL", { maximumFractionDigits: 0 }), animate);
  }

  refreshData(handle: string, accountId: string, datasetsToRefresh: string[]) {
    this.unFootprint
      .getUnFootprintData(handle, accountId, datasetsToRefresh)
      .then((response: AxiosResponse<GraphQLResponse>) => {
        // Check if GraphQL has reported an error.
        if (response.data.errors !== undefined && response.data.errors !== null) {
          console.error("unFootprint API error:");
          console.error(response.data.errors);
          return;
        }

        // Set the data in storage.
        log("Data retrieved: ", response.data.data);
        if (response.data.data.profiles !== undefined) {
          this.storageService.setProfileData(response.data.data.profiles[0]);
        }
        if (response.data.data.statistics !== undefined) {
          this.storageService.setStatisticsData(response.data.data.statistics);
        }

        this.storageService.setDataExpiryDate();

        // Refresh the elements with the new data.
        this.populate(datasetsToRefresh);
      })
      .catch(function (error: any) {
        console.error("unFootprint error reaching API:");
        console.error(error);
      });
  }
}

// Initialize services.
let attributeService = new AttributeService();
const config = getConfig(attributeService);

const instance = axios.create({
  baseURL: config.apiUri,
  timeout: config.apiTimeout,
  headers: { "X-Origin": "unFootprint Widget" },
});

let storageService = new StorageService(config, attributeService);
let unFootprintService = new UnFootprintService(instance);
let comparisonService = new Co2ComparisonService();
let animateService = new AnimateService(attributeService, config);

function log(...data: any) {
  if (config.loggingEnabled || storageService.isLoggingEnabled()) {
    console.log(data);
  }
}

function info(...data: any) {
  if (config.loggingEnabled || storageService.isLoggingEnabled()) {
    console.info(data);
  }
}
function error(...data: any) {
  if (config.loggingEnabled || storageService.isLoggingEnabled()) {
    console.error(data);
  }
}

function getConfig(attributeService: AttributeService): Config {
  return require(`./config.${getEnvironment(attributeService)}.json`);
}

function getEnvironment(attributeService: AttributeService) {
  /* First check to see if the widget itself specifies which environment to connect to. */
  let environment = attributeService.getEnvironment();

  if (environment != null) {
    return environment;
  }

  /* Check to see if the environment is specified in the local storage. */
  environment = localStorage.getItem("widgetEnvironment");

  if (environment != null) {
    return environment;
  }

  /* No environment found so far. Check the URL for clues. */
  let url = window.location.href;

  if (url.indexOf("https://acc.") !== -1) {
    return "acceptance";
  } else if (url.indexOf("https://test.") !== -1) {
    return "test";
  } else if (url.indexOf("localhost") !== -1) {
    return "development";
  } else {
    return "production";
  }
}

/**
 * Load the data for the widgets.
 */
function load() {
  if ((window as any).widgets === undefined) {
    (window as any).widgets = new Widgets(unFootprintService, attributeService, storageService, comparisonService, animateService);
    (window as any).widgets.getData();
  }
}

info("Widget javascript loaded");

/*
 * If the DOM is already loaded, start loading the widget data.
 * It's important not to load before the DOM is loaded, as the widget HTML is then also not yet available.
 * If the DOM isn't loaded, wait till it is.
 */
if (document.readyState !== "loading") {
  info("DOM was already loaded, load widgets");
  load();
} else {
  document.onreadystatechange = () => {
    info("Document ready state: " + document.readyState);
    if (document.readyState !== "loading") {
      info("DOM loaded, load widgets");
      load();
    }
  };
}
